.app {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
  height: 100vh;
  background-color: #f8f9fd;
}

input[type="text"],
input[type="number"],
input[type="password"] {
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

input[type="submit"] {
  cursor: pointer;
  font-size: 15px;
  background: #af1010;
  border: 1px solid #af1010;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
}

input[type="submit"]:hover {
  background: #af1010;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.loginForm {
  background-color: white;
  border-radius: 15px;
  padding: 2rem;
  width: 50vw;
  min-width: 315px;
  max-width: 600px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.listContainer {
  display: flex;
}

.error {
  color: red;
}

.success {
  color: green;
}

.title {
  font-size: 1.6rem;
  margin-bottom: 35px;
  text-align: center;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 10px;
}

.logoStyle {
  width: 25vw;
  min-width: 2vw;
  max-width: 10vw;
}

label {
  margin-top: 0.6rem;
  margin-bottom: 0;
}

.link {
  color: #000 !important;
  cursor: pointer;
  /* text-decoration: none !important; */
}

.link:hover {
  color: #af1010 !important;
  cursor: pointer;
  text-decoration: underline !important;
}

.highlighted-date {
  background-color: yellow;
  /* Add your desired highlighting style */
  /* Add any other styling you want for the highlighted dates */
}